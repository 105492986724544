import {Component, EventEmitter, Input, OnChanges, OnDestroy, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {faTrashAlt} from '@fortawesome/free-regular-svg-icons';
import {faCrosshairs, faMousePointer, faPlus} from '@fortawesome/free-solid-svg-icons';

import {ALLOWED_NEWS_PATH_TYPES, HIGHLIGHT_COLORS} from '../../../shared';

import {BrowserService} from '../../../services';

import {NewsFilter, NewsPath, NewsPayload, UniversalSelector} from '../../../models';


@Component({
  selector: 'app-news-configuration',
  templateUrl: './news-configuration.component.html',
  styleUrls: ['./news-configuration.component.scss']
})
export class NewsConfigurationComponent implements OnChanges, OnDestroy {
  @Input() browserState: boolean;
  @Input() isWaitingForSelection: boolean;
  @Input() payload: NewsPayload;
  @Output() browserRequested = new EventEmitter<NewsPath>();

  selectedFilterIndex: number;
  selectedFilter: NewsFilter;

  readonly faCrosshairs = faCrosshairs;
  readonly faMousePointer = faMousePointer;
  readonly faPlus = faPlus;
  readonly faTrashAlt = faTrashAlt;

  private onDestroy$ = new Subject();

  constructor(
    private browserService: BrowserService
  ) {
    this.browserService.updateHightlightingElements$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
      this.highlightSelectedFilterPaths();
    });
  }

  ngOnChanges(): void {
    if (!this.selectedFilterIndex && !this.selectedFilter && this.payload && this.payload.filters) {
      this.selectedFilterIndex = 0;
      this.selectedFilter = this.payload.filters[this.selectedFilterIndex];
    }
    this.browserService.setBrowserState(!!this.selectedFilter);
  }

  addFilter() {
    this.payload.filters.push(new NewsFilter());
  }

  addPath(filter: NewsFilter) {
    if (this.availablePathTypes(filter).length) {
      filter.paths.push(new NewsPath(null, null, this.availablePathTypes(filter)[0], filter.id));
    } else {
      throw new Error('No more paths available');
    }
  }

  availablePathTypes(filter: NewsFilter, additionalPathType: string = null) {
    const avaliablePathTypes = ALLOWED_NEWS_PATH_TYPES.filter((pathType: string) => {
      let found = false;
      filter.paths.map((path: NewsPath) => {
        if (path.type === pathType) {
          found = true;
        }
      });
      return !found;
    });
    if (additionalPathType) {
      avaliablePathTypes.push(additionalPathType);
    }
    return avaliablePathTypes;
  }

  deleteFilter(index: number) {
    if (index === this.selectedFilterIndex) {
      this.selectedFilter = null;
      this.selectedFilterIndex = null;
      if (this.browserState) {
        this.browserService.setBrowserState(false);
      }
      this.highlightSelectedFilterPaths();
    }
    this.payload.filters.splice(index, 1);
  }

  deletePath(filter: NewsFilter, index: number) {
    filter.paths.splice(index, 1);
  }

  filterSiblings(filterId: number) {
    return this.payload.filters.filter(filter => {
      return filterId && filter.id && filter.id !== filterId && filter.parentId !== filterId;
    });
  }

  selectFilter(filter: NewsFilter, index: number) {
    this.selectedFilter = filter;
    this.selectedFilterIndex = index;
    if (!this.browserState) {
      this.browserService.setBrowserState(true);
    }
    this.highlightSelectedFilterPaths();
  }

  selectPath(path: NewsPath) {
    this.browserRequested.emit(path);
  }

  highlightSelectedFilterPaths() {
    const selectors: UniversalSelector[] = [];

    this.selectedFilter.paths = this.selectedFilter.paths.map((path: NewsPath) => {
      if (!path.color) {
        path.color = HIGHLIGHT_COLORS[Math.floor(Math.random() * HIGHLIGHT_COLORS.length)];
      }

      if (path.path) {
        selectors.push(
          new UniversalSelector(
            path.color,
            path.type,
            path.path,
            'xpath'
          )
        );
      }
      return path;
    });

    console.log('selectors', selectors);

    this.browserService.hightlightElements(selectors);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
