import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SelectionService {

  private waitingForSelectionState = new Subject<boolean>();
  readonly waitingForSelectionState$ = this.waitingForSelectionState.asObservable();

  private selectedElement = new Subject<any>();
  readonly selectedElement$ = this.selectedElement.asObservable();

  waitForSelection(state: boolean) {
    this.waitingForSelectionState.next(state);
  }

  selectElement(element: any = null) {
    this.selectedElement.next(element);
    this.waitingForSelectionState.next(false);
  }
}
