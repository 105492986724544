import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {map} from 'rxjs/operators';

import {environment} from '../../environments/environment';

import {ToastrService} from 'ngx-toastr';

import {User} from '../models';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private _currentUser: User = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : null;

  constructor(
    private http: HttpClient,
    private router: Router,
    private toaster: ToastrService
  ) {
  }

  get currentUser(): User {
    return this._currentUser;
  }

  set currentUser(user: User) {
    this._currentUser = user;
    if (user) {
      localStorage.setItem('currentUser', JSON.stringify(user));
    } else if (localStorage.getItem('currentUser')) {
      localStorage.removeItem('currentUser');
    }
  }

  refreshAccessToken() {
    return this.http.post(
      `${environment.api.baseUrl}${environment.api.routes.token.refresh}`,
      null
    ).pipe(map((response: any) => {
      if (response.success) {
        this._currentUser.accessToken = response.response.access_token;
      }
      return response;
    }));
  }

  revokeRefreshToken() {
    return this.http.post(
      `${environment.api.baseUrl}${environment.api.routes.token.revoke}`,
      null
    ).pipe(map((response: any) => {
      if (response.success) {
        this.currentUser = null;
      }
      return response;
    }));
  }

  signIn(username: string, password: string) {
    return this.http.post(
      `${environment.api.baseUrl}${environment.api.routes.auth.signIn}`,
      {
        username: username,
        password: password
      }
    ).pipe(map((response: any) => {
      if (response.success) {
        this.currentUser = new User(username, response.response.access_token, response.response.refresh_token);
      }
      return response;
    }));
  }

  signOut() {
    return this.http.post(
      `${environment.api.baseUrl}${environment.api.routes.auth.signOut}`,
      null
    ).pipe(map((response: any) => {
      if (response.success) {
        this.currentUser = null;
      }
      return response;
    }));
  }
}
