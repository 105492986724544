import {ALLOWED_HEADER_KEYS} from '../shared';

import {BaseGuide} from './guide';

export class CreditCardGuide extends BaseGuide {
  payload: CreditCardPayload = new CreditCardPayload();

  constructor(
    id: number = null,
    name: string = 'New credit card guide',
    accessToken: string = null,
    url: string = null,
    currentFrequency: number = null,
    payload: CreditCardPayload = new CreditCardPayload()
  ) {
    super('creditcard', id, name, accessToken, url, currentFrequency);
    this.payload = payload;
  }
}

export class CreditCardApiConfig {
  params: CreditCardParams = new CreditCardParams();
  responseKeyPath: string[] = [];
  pagination: CreditCardPagination = new CreditCardPagination();

  constructor(
    params: CreditCardParams = new CreditCardParams(),
    responseKeyPath: string[] = [],
    pagination: CreditCardPagination = new CreditCardPagination()
  ) {
    this.params = params;
    this.responseKeyPath = responseKeyPath;
    this.pagination = pagination;
  }
}

export class CreditCardEntryPoint {
  sourceUrl: string;
  spiderKey: string;

  constructor(
    sourceUrl: string = null,
    spiderKey: string = null
  ) {
    this.sourceUrl = sourceUrl;
    this.spiderKey = spiderKey;
  }
}

export class CreditCardPayload {
  apiConfig: CreditCardApiConfig = new CreditCardApiConfig();
  entryPoint: CreditCardEntryPoint = new CreditCardEntryPoint();
  headers: CreditCardHeader[] = [];

  constructor(
    apiConfig: CreditCardApiConfig = new CreditCardApiConfig(),
    entryPoint: CreditCardEntryPoint = new CreditCardEntryPoint(),
    headers: CreditCardHeader[] = []
  ) {
    this.apiConfig = apiConfig;
    this.entryPoint = entryPoint;
    this.headers = headers;
  }
}

export class CreditCardHeader {
  value: string;

  private _key: string;

  constructor(
    key: string,
    value: string = null
  ) {
    this.key = key;
    this.value = value;
  }

  get key(): string {
    return this._key;
  }

  set key(key: string) {
    if (ALLOWED_HEADER_KEYS.indexOf(key) > -1) {
      this._key = key;
    } else {
      throw new Error(`CreditCardHeader key "${key}" is not supported`);
    }
  }
}

export class CreditCardPagination {
  key: string;
  start: number;
  step: number;
  stop: number;

  constructor(
    key: string = null,
    start: number = null,
    step: number = null,
    stop: number = null
  ) {
    this.key = key;
    this.start = start;
    this.step = step;
    this.stop = stop;
  }
}

abstract class CreditCardParam {
  key: string;
  private _values: string[] = [];

  constructor(
    key: string = null,
    values: string = null
  ) {
    this.key = key;
    this.values = values;
  }

  get values(): string {
    return this._values.join(',');
  }

  set values(values: string) {
    if (values) {
      if (values.indexOf(',') > -1) {
        this._values = values.split(',');
      } else {
        this._values = [values];
      }
    }
  }
}

export class CreditCardParamDynamic extends CreditCardParam {
  isMultiple: boolean = false;


  constructor(
    key: string = null,
    values: string = null,
    isMultiple: boolean = null
  ) {
    super(key, values);
    this.isMultiple = isMultiple
  }
}

export class CreditCardParamStatic extends CreditCardParam {
}

export class CreditCardParams {
  dynamicList: CreditCardParamDynamic[] = [];
  staticList: CreditCardParamStatic[] = [];

  constructor(
    dynamicList: CreditCardParamDynamic[] = [],
    staticList: CreditCardParamStatic[] = []
  ) {
    this.dynamicList = dynamicList;
    this.staticList = staticList;
  }
}
