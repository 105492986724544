import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './guards/auth.guard';

import {ConfigurationComponent} from './components/configuration/configuration.component';
import {GuideListComponent} from './components/guide-list/guide-list.component';
import {SignInComponent} from './components/auth/sign-in/sign-in.component';

const appRoutes: Routes = [
  {
    path: 'configuration/:id',
    component: ConfigurationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'configuration',
    component: GuideListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'sign-in',
    component: SignInComponent
  },
  {path: '**', redirectTo: '/configuration'}
];

export const RoutingModule = RouterModule.forRoot(appRoutes);
