import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, filter, switchMap, take} from 'rxjs/operators';

import {AuthenticationService} from '../services';


@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(private authenticationService: AuthenticationService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = this.addAuthenticationToken(request);
    return next.handle(request).pipe(catchError((err) => {
      if (
        request.url.includes('token/refresh') ||
        request.url.includes('sign-in')
      ) {
        if (request.url.includes('token/refresh')) {
          this.authenticationService.signOut();
        }

        return throwError(err);
      }

      if (err.status !== 401) {
        return throwError(err);
      }

      if (this.refreshTokenInProgress) {
        return this.refreshTokenSubject
          .pipe(
            filter(result => result !== null),
            take(1),
            switchMap(() => next.handle(this.addAuthenticationToken(request)))
          );
      } else {
        this.refreshTokenInProgress = true;
        this.refreshTokenSubject.next(null);

        return this.authenticationService
          .refreshAccessToken()
          .pipe(
            switchMap((response: any) => {
              if (response.success) {
                this.refreshTokenInProgress = false;
                this.refreshTokenSubject.next(response.response.access_token);

                return next.handle(this.addAuthenticationToken(request));
              } else {
                this.refreshTokenInProgress = false;

                this.authenticationService.signOut();
                return throwError(err);
              }
            }),
            catchError((error: any) => {
              this.refreshTokenInProgress = false;

              this.authenticationService.signOut();
              return throwError(error);
            })
          );
      }
    }));
  }

  addAuthenticationToken(request) {
    const currentUser = this.authenticationService.currentUser;
    let authToken = null;
    if (currentUser) {
      if (request.url.includes('/api/token/')) {
        authToken = currentUser.refreshToken;
      } else {
        authToken = currentUser.accessToken;
      }
    }
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${authToken}`
      }
    });
  }
}
