import {BaseGuide} from './guide';
import {SOURCE_SUB_TYPE_SOCIAL_MEDIA_INSTAGRAM, SOURCE_TYPE_SOCIAL_MEDIA, ALLOWED_SOURCE_SUB_TYPES_SOCIAL_MEDIA} from "../shared";

export class SocialMediaGuide extends BaseGuide {
  payload: SocialMediaPayload = new SocialMediaPayload();

  constructor(
    id: number = null,
    name: string = 'New marketplace guide',
    accessToken: string = null,
    url: string = null,
    currentFrequency: number = null,
    visibility: boolean = true,
    payload: SocialMediaPayload = new SocialMediaPayload(),
    sourceSubType: string = null,
  ) {
    super(SOURCE_TYPE_SOCIAL_MEDIA, id, name, accessToken, url, currentFrequency, visibility, sourceSubType);
    this.payload = payload;
    if (!this.sourceSubType) {
      this.sourceSubType = Object.keys(ALLOWED_SOURCE_SUB_TYPES_SOCIAL_MEDIA)[0];
    }
  }
}

export class SocialMediaPayload {
}

export class InstagramGuide extends SocialMediaGuide {
  payload: InstagramPayload = new InstagramPayload();

  constructor(
    id: number = null,
    name: string = 'New instagram guide',
    currentFrequency: number = null,
    accessToken: string = null,
    visibility: boolean = true,
    payload: InstagramPayload = new InstagramPayload()
  ) {
    super(id, name, accessToken, 'https://www.instagram.com/', currentFrequency, visibility, payload, SOURCE_SUB_TYPE_SOCIAL_MEDIA_INSTAGRAM);
  }
}

export class InstagramPayload extends SocialMediaPayload{
  entryPoint: InstagramEntryPoint = new InstagramEntryPoint();

  constructor(entryPoint: InstagramEntryPoint = new InstagramEntryPoint()) {
    super();
    this.entryPoint = entryPoint;
  }
}

export class InstagramEntryPoint {
  type: string = null;
  _values: string[] = null;

  constructor(type: string = '', values: string = '') {
    this.type = type;
    this.values = values;
  }

  get values(): string {
    return this._values ? this._values.join(',') : null;
  }

  set values(values: string) {
    if (values) {
      if (values.indexOf(',') > -1) {
        this._values = values.split(',');
      } else {
        this._values = [values];
      }
    }
  }
}
