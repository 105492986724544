export const ALLOWED_FIELD_TYPES = {'single': 'Single', 'multiple': 'Multiple', 'images': 'Image'};

export const ALLOWED_MARKET_FIELD_TYPES = {'single': 'Single', 'multiple': 'Multiple', 'images': 'Image', 'sub_item': 'Sub Item'};

export const ALLOWED_HEADER_KEYS = [
  'Accept',
  'Accept-Charset',
  'Accept-Encoding',
  'Accept-Language',
  'Connection',
  'Content-Type',
  'X-Requested-With',
  'X-V' // Custom header for some credit card marketplaces
];
export const ALLOWED_NEWS_PATH_TYPES = [
  'Abstract',
  'Author',
  'Body',
  'Date',
  'Headline',
  'Image',
  'Link',
  'Source',
  'URL'
];
export const ALLOWED_PAGE_TYPES = {'edge': 'Item list', 'node': 'Single item'};
export const ALLOWED_RENDER_MODE_TYPES = {'default': 'Default', 'selenium': 'Selenium', 'scraping_bee': 'Scraping Bee'};
export const ALLOWED_PATH_TYPES = {'css': 'CSS', 'xpath': 'XPath'};
export const ALLOWED_LEAD_TYPES = {'request': 'Request', 'xpath': 'XPath', 'selector': 'Selector'};

export const SOURCE_TYPE_CREDITCARD = 'creditcard';
export const SOURCE_TYPE_FORUM = 'forum';
export const SOURCE_TYPE_MARKETPLACE = 'marketplace';
export const SOURCE_TYPE_NEWS = 'news';
export const SOURCE_TYPE_SOCIAL_MEDIA = 'socialMedia';
export const SOURCE_TYPE_DATABREACH = 'databreach';
export const SOURCE_TYPE_DATADUMP = 'datadump';

export const SOURCE_SUB_TYPE_SOCIAL_MEDIA_INSTAGRAM = 'instagram';
export const SOURCE_SUB_TYPE_SOCIAL_MEDIA_TWITTER = 'twitter';
export const SOURCE_SUB_TYPE_SOCIAL_MEDIA_FACEBOOK = 'facebook';

export const ALLOWED_SOURCE_TYPES = {
  [SOURCE_TYPE_CREDITCARD]: 'Credit Card',
  [SOURCE_TYPE_FORUM]: 'Forum',
  [SOURCE_TYPE_MARKETPLACE]: 'Marketplace',
  [SOURCE_TYPE_NEWS]: 'News',
  [SOURCE_TYPE_SOCIAL_MEDIA]: 'Social Media',
  [SOURCE_TYPE_DATABREACH]: 'Databreach',
  [SOURCE_TYPE_DATADUMP]: 'Datadump',
};

export const ALLOWED_SOURCE_SUB_TYPES_SOCIAL_MEDIA = {
  [SOURCE_SUB_TYPE_SOCIAL_MEDIA_TWITTER]: 'Twitter',
  [SOURCE_SUB_TYPE_SOCIAL_MEDIA_INSTAGRAM]: 'Instagram',
  [SOURCE_SUB_TYPE_SOCIAL_MEDIA_FACEBOOK]: 'Facebook',
};

export const FORUM_PATH_NAMES = {
  fo_block: 'Block',
  fo_block_newest_post: 'Newest post',
  fo_block_links: 'Links',
  fo_block_names: 'Names',
  fo_block_description: 'Description',
  fp_thread_block: 'Block',
  fp_thread_user_link: 'User link',
  fp_thread_user_name: 'User name',
  fp_thread_title: 'Thread title',
  fp_newest_post: 'Newest post',
  fp_first_page_link: 'First page link',
  fp_last_page_link: 'Last page link',
  fp_next_page_link: 'Next page link',
  th_post_block: 'Post block',
  th_post_content: 'Post content',
  th_post_user: 'User',
  th_post_user_url: 'User URL',
  th_post_user_image: 'User image',
  th_post_user_role: 'User role',
  th_post_user_posts: 'User posts',
  th_post_user_location: 'User location',
  th_post_user_signature: 'User signature',
  th_post_url: 'Post URL',
  th_post_created_time: 'Created time',
  th_next_page: 'Next page link',
  th_previous_page: 'Previous page link'
};
export const HIGHLIGHT_COLORS = [
  '#be4a2f',
  '#ead4aa',
  '#b86f50',
  '#733e39',
  '#3e2731',
  '#a22633',
  '#e43b44',
  '#f77622',
  '#feae34',
  '#fee761',
  '#63c74d',
  '#265c42',
  '#193c3e',
  '#124e89',
  '#0099db',
  '#2ce8f5',
  '#3a4466',
  '#262b44',
  '#ff0044',
  '#68386c',
  '#b55088',
  '#f6757a',
  '#e8b796',
  '#c28569'
];
export const NEWS_PATH_NAMES = {
  'URL': 'URL',
  'Body': 'Body',
  'Headline': 'Headline',
  'Source': 'Source',
  'Image': 'Image',
  'Author': 'Author',
  'Date': 'Date'
};
export const PER_PAGE_VALUES = [10, 25, 50, 100];

export const DEFAULT_CURRENT_FREQUENCY = 1440;
