import {ALLOWED_SOURCE_TYPES, DEFAULT_CURRENT_FREQUENCY} from '../shared';
import {Silo} from './silo';

export class BaseGuide {
  id: number;

  accessToken: string;
  name: string;
  url: string;
  silos: number[] | Silo[] = [];
  visibility = true;
  sourceSubType: string = null;
  currentFrequency: number = null;
  private _sourceType: string;

  constructor(
    sourceType: string,
    id: number = null,
    name: string = 'New guide',
    accessToken: string = null,
    url: string = null,
    currentFrequency: number = null,
    visibility: boolean = true,
    sourceSubType: string = null
  ) {
    this.sourceType = sourceType;

    this.id = id;
    this.name = name;
    this.accessToken = accessToken;
    this.url = url;
    this.visibility = visibility;
    this.sourceSubType = sourceSubType;
    this.currentFrequency = currentFrequency ? currentFrequency : DEFAULT_CURRENT_FREQUENCY;
  }

  get sourceType(): string {
    return this._sourceType;
  }

  set sourceType(sourceType: string) {
    if (Object.keys(ALLOWED_SOURCE_TYPES).indexOf(sourceType) > -1) {
      this._sourceType = sourceType;
    } else {
      throw new Error(`Source type "${sourceType}" is not supported`);
    }
  }
}
