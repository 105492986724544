export const environment = {
  production: false,
  api: {
    baseUrl: '/api',
    routes: {
      auth: {
        signIn: '/auth/sign-in',
        signOut: '/auth/sign-out'
      },
      guide: '/guide',
      page: '/page',
      resource: '/resource',
      silo: '/silo',
      token: {
        refresh: '/token/refresh',
        revoke: '/token/revoke'
      }
    }
  }
};

