import {Component, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {first, takeUntil} from 'rxjs/operators';

import {faSave} from '@fortawesome/free-regular-svg-icons';
import {faSignOutAlt, faSpinner, faVial} from '@fortawesome/free-solid-svg-icons';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import { Location } from '@angular/common';

import {
  ALLOWED_SOURCE_TYPES,
  FORUM_PATH_NAMES,
  HIGHLIGHT_COLORS,
  SOURCE_TYPE_CREDITCARD,
  SOURCE_TYPE_FORUM,
  SOURCE_TYPE_MARKETPLACE,
  SOURCE_TYPE_NEWS,
  SOURCE_TYPE_SOCIAL_MEDIA,
  SOURCE_TYPE_DATABREACH,
  SOURCE_TYPE_DATADUMP,
  ALLOWED_SOURCE_SUB_TYPES_SOCIAL_MEDIA,
  SOURCE_SUB_TYPE_SOCIAL_MEDIA_INSTAGRAM,
  SOURCE_SUB_TYPE_SOCIAL_MEDIA_TWITTER,
  SOURCE_SUB_TYPE_SOCIAL_MEDIA_FACEBOOK,
} from '../../shared';

import {
  AuthenticationService,
  BrowserService,
  GuideService,
  PathService,
  SelectionService,
  SiloService
} from '../../services';

import {
  CreditCardGuide,
  ForumGuide,
  ForumPath,
  MarketplaceGuide,
  NewsFilter,
  NewsGuide,
  NewsPath,
  Silo,
  UniversalSelector,
  SocialMediaGuide,
  InstagramGuide, BaseGuide
} from '../../models';


@Component({
  templateUrl: './configuration.component.html'
})
export class ConfigurationComponent implements OnInit, OnChanges, OnDestroy {
  activeTabId = 'base';
  browserState = this.browserService.lastBrowserState;
  browserUrl = '';
  guide: any;
  guideUrlIsLoaded = false;
  initialJSONGuide: any;
  isSaving = false;
  isTesting = false;

  isWaitingForSelection = false;
  multiSelectSettings = {
    enableFilterSelectAll: false,
    enableSearchFilter: false,
    labelKey: 'name',
    text: '-- Please select --'
  };
  silos: Silo[] = [];

  readonly ALLOWED_SOURCE_TYPES = ALLOWED_SOURCE_TYPES;
  readonly FORUM_PATH_NAMES = FORUM_PATH_NAMES;
  readonly HIGHLIGHT_COLORS = HIGHLIGHT_COLORS;
  readonly SOURCE_TYPE_CREDITCARD = SOURCE_TYPE_CREDITCARD;
  readonly SOURCE_TYPE_FORUM = SOURCE_TYPE_FORUM;
  readonly SOURCE_TYPE_MARKETPLACE = SOURCE_TYPE_MARKETPLACE;
  readonly SOURCE_TYPE_NEWS = SOURCE_TYPE_NEWS;
  readonly SOURCE_TYPE_SOCIAL_MEDIA = SOURCE_TYPE_SOCIAL_MEDIA;
  readonly SOURCE_TYPE_DATABREACH = SOURCE_TYPE_DATABREACH;
  readonly SOURCE_TYPE_DATADUMP = SOURCE_TYPE_DATADUMP;
  readonly ALLOWED_SOURCE_SUB_TYPES_SOCIAL_MEDIA = ALLOWED_SOURCE_SUB_TYPES_SOCIAL_MEDIA;
  readonly SOURCE_SUB_TYPE_SOCIAL_MEDIA_INSTAGRAM = SOURCE_SUB_TYPE_SOCIAL_MEDIA_INSTAGRAM;
  readonly SOURCE_SUB_TYPE_SOCIAL_MEDIA_TWITTER = SOURCE_SUB_TYPE_SOCIAL_MEDIA_TWITTER;
  readonly SOURCE_SUB_TYPE_SOCIAL_MEDIA_FACEBOOK = SOURCE_SUB_TYPE_SOCIAL_MEDIA_FACEBOOK;


  readonly faSave = faSave;
  readonly faSignOutAlt = faSignOutAlt;
  readonly faSpinner = faSpinner;
  readonly faVial = faVial;

  private onDestroy$ = new Subject();

  constructor(
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private browserService: BrowserService,
    private guideService: GuideService,
    private modalService: NgbModal,
    private pathService: PathService,
    private router: Router,
    private selectionService: SelectionService,
    private siloService: SiloService,
    private toaster: ToastrService,
    private location: Location
  ) {
    this.browserService.browserState$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((state: boolean) => {
      this.browserState = state;
    }, error => {
      this.toaster.error(error);
      console.error(error);
    });
    this.browserService.urlLoaded$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((response: any) => {
      this.browserUrl = response.url;
    }, error => {
      this.toaster.error(error);
      console.error(error);
    });
    this.selectionService.waitingForSelectionState$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((state: boolean) => {
      this.isWaitingForSelection = state;
    }, error => {
      this.toaster.error(error);
      console.error(error);
    });
  }

  ngOnInit(): void {
    this.activatedRoute.params.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(params => {
      const guideId = parseInt(params.id, 10);
      if (guideId && guideId !== 0) {
        this.guideService.getGuide(guideId).pipe(
          takeUntil(this.onDestroy$)
        ).subscribe((response: any) => {
          if (response.success) {
            this.guide = response.response;
            this.initialJSONGuide = JSON.stringify(this.guide);
            this.loadSilos();
          } else {
            this.toaster.error(response.response);
            console.error(response.response);
          }
        }, error => {
          console.error(error);
        });
      } else {
        this.guide = new CreditCardGuide();
        this.initialJSONGuide = JSON.stringify(this.guide);
        this.loadSilos();
      }
    });
  }

  ngOnChanges() {
    this.browserState = this.browserService.lastBrowserState;
  }

  onTabChange(event: any) {
    if (event.nextId === 'list') {
      if (JSON.stringify(this.guide) === this.initialJSONGuide) {
        this.router.navigate(['/configuration']);
      } else if (confirm('Are you sure? All unsaved changes will be lost!')) {
        this.router.navigate(['/configuration']);
      } else {
        event.preventDefault();
      }
    } else if (this.isWaitingForSelection) {
      this.selectionService.selectElement();
    }
    if (event.nextId === 'guide') {
      if (!this.guideUrlIsLoaded) {
        if (this.guide.url && this.guide.sourceType !== 'creditcard') {
          this.browserUrl = this.guide.url;
          this.browserService.loadUrl(this.guide.url);
        }
        this.guideUrlIsLoaded = true;
      }
      this.setBrowserState(['forum', 'news'].indexOf(this.guide.sourceType) > -1);
      this.fillDefaultData();
      this.highlightDefaultGuidePaths();
    } else if (event.nextId === 'base') {
      this.browserService.hightlightElements();
    }
  }

  fillDefaultData() {
    switch (this.guide.sourceType) {
      case 'forum':
        const missedNames = Object.keys(FORUM_PATH_NAMES);
        missedNames.map((key: string) => {
          const exist = this.guide.payload.paths.find(function (element, index, array) {
            return element.name == key
          });

          if (!exist) {
            this.guide.payload.paths.push(new ForumPath(null, key));
          }
        });
    }
  }

  highlightDefaultGuidePaths() {
    const selectors: UniversalSelector[] = [];

    switch (this.guide.sourceType) {
      case 'forum':
        this.guide.payload.paths = this.guide.payload.paths.map((path: ForumPath) => {
          if (path.name.startsWith('fo')) {
            if (!path.color) {
              path.color = HIGHLIGHT_COLORS[Math.floor(Math.random() * HIGHLIGHT_COLORS.length)];
            }

            if (path.path) {
              selectors.push(
                new UniversalSelector(
                  path.color,
                  path.name,
                  path.path,
                  path.pathType
                )
              );
            }
          }
          return path;
        });
        break;
      case 'news':
        if (this.guide.payload.filters.length) {
          this.guide.payload.filters.map((filter: NewsFilter, index: number) => {
            if (index === 0) {
              filter.paths = filter.paths.map((path: NewsPath) => {
                if (!path.color) {
                  path.color = HIGHLIGHT_COLORS[Math.floor(Math.random() * HIGHLIGHT_COLORS.length)];
                }

                if (path.path) {
                  selectors.push(
                    new UniversalSelector(
                      path.color,
                      path.type,
                      path.path,
                      'xpath'
                    )
                  );
                }
                return path;
              });
            }
            return filter;
          });
        }
        break;
    }

    this.browserService.hightlightElements(selectors);
  }

  loadSilos() {
    this.siloService.getSilos().subscribe((response: any) => {
      if (response.success) {
        this.silos = response.response;
        // Replace guide silo ID's by silo objects
        this.guide.silos = this.silos.filter(
          silo => this.guide.silos && this.guide.silos.indexOf(silo.id) > -1
        );
      } else {
        this.toaster.error('Unable to get silos. Please try again later');
      }
    }, error => console.error(error));
  }

  signOut() {
    this.authenticationService.signOut().subscribe(
      (response: any) => {
        if (response.success) {
          this.router.navigate(['/sign-in']);
          this.toaster.success(response.response);
        } else {
          this.toaster.error(response.response);
        }
      },
      error => {
        console.error(error);
      });
  }

  saveGuide() {
    if (!this.guide.url){
      this.toaster.error('Guide URL is required');
      return;
    }
    this.isSaving = true;
    // Replace silos with array their ID's

    const guideToSend = JSON.parse(JSON.stringify(this.guide));
    guideToSend.silos = this.guide.silos.map(silo => silo.id);
    this.guideService.saveGuide(guideToSend).pipe(
      first()
    ).subscribe((response: any) => {
      if (response.success) {
        this.toaster.success('Saved successfully');
        if (!this.guide.id) {
          this.guide.id = response.response;
          this.location.replaceState('configuration/' + this.guide.id)
        }
        this.initialJSONGuide = JSON.stringify(this.guide);
      } else {
        this.toaster.error(response.response);
        console.error(response.response);
      }
      this.isSaving = false;
    }, error => {
      this.isSaving = false;
      console.error(error);
    });
  }

  setBrowserState(state: boolean) {
    this.browserState = state;
    this.browserService.setBrowserState(this.browserState);
  }

  updateGuideClassBySourceType(event) {
    this.setBrowserState(false);
    switch (this.guide.sourceType) {
      case SOURCE_TYPE_CREDITCARD:
        this.guide = new CreditCardGuide(this.guide.id, this.guide.name, this.guide.accessToken, this.guide.url, this.guide.currentFrequency);
        break;
      case SOURCE_TYPE_FORUM:
        this.guide = new ForumGuide(this.guide.id, this.guide.name, this.guide.accessToken, this.guide.url, this.guide.currentFrequency);
        this.setBrowserState(true);
        break;
      case SOURCE_TYPE_MARKETPLACE:
        this.guide = new MarketplaceGuide(this.guide.id, this.guide.name, this.guide.accessToken, this.guide.url, this.guide.currentFrequency);
        break;
      case SOURCE_TYPE_NEWS:
        this.guide = new NewsGuide(this.guide.id, this.guide.name, this.guide.accessToken, this.guide.url, this.guide.currentFrequency);
        this.setBrowserState(true);
        break;
      case SOURCE_TYPE_SOCIAL_MEDIA:
        this.guide = new SocialMediaGuide(this.guide.id, this.guide.name, this.guide.accessToken, this.guide.url, this.guide.currentFrequency, this.guide.visibility,  this.guide.sourceSubType);
        break;
      case SOURCE_TYPE_DATABREACH:
      case SOURCE_TYPE_DATADUMP:
        this.guide = new BaseGuide(this.guide.sourceType, this.guide.id, this.guide.name, this.guide.accessToken, this.guide.url, this.guide.currentFrequency, this.guide.visibility,  this.guide.sourceSubType);
        break;
      default:
        event.preventDefault();
        throw new Error(`Source type "${this.guide.sourceType}" is not supported`);
    }
  }

  updateSocialMediaClassBySourceType(event) {
    if (this.guide.sourceType == SOURCE_TYPE_SOCIAL_MEDIA) {
      switch (this.guide.sourceSubType) {
        case SOURCE_SUB_TYPE_SOCIAL_MEDIA_INSTAGRAM:
           this.guide = new InstagramGuide(this.guide.id, this.guide.name, this.guide.currentFrequency, this.guide.accessToken, this.guide.visibility);
          break;
        case SOURCE_SUB_TYPE_SOCIAL_MEDIA_TWITTER:
        case SOURCE_SUB_TYPE_SOCIAL_MEDIA_FACEBOOK:
          this.guide = new SocialMediaGuide(this.guide.id, this.guide.name, this.guide.accessToken, this.guide.url, this.guide.currentFrequency, this.guide.visibility,  this.guide.sourceSubType);
          break;
      }
    }
  }

  waitForSelection(item: any) {
    this.selectionService.waitForSelection(true);
    if (!this.browserState) {
      this.setBrowserState(true);
    }
    this.selectionService.selectedElement$.pipe(
      first()
    ).subscribe(selectedElement => {
      if (selectedElement) {
        item.element = selectedElement;
        this.pathService.updateItemPath(item, this.guide.sourceType);
        this.browserService.updateHighlightingElements();
      }
    }, error => {
      this.toaster.error(error);
      console.error(error);
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  isDrivenGuide() {
    if (this.guide) {
      if ([SOURCE_TYPE_CREDITCARD, SOURCE_TYPE_FORUM, SOURCE_TYPE_MARKETPLACE, SOURCE_TYPE_NEWS].includes(this.guide.sourceType)) {
        return true;
      }

      if (this.guide.sourceType == SOURCE_TYPE_SOCIAL_MEDIA) {
        if (this.guide.sourceSubType == SOURCE_SUB_TYPE_SOCIAL_MEDIA_INSTAGRAM) {
          return true;
        }
      }
    }

    return false;
  }
}
