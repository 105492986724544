import {ALLOWED_FIELD_TYPES, ALLOWED_LEAD_TYPES, ALLOWED_PAGE_TYPES, ALLOWED_PATH_TYPES, ALLOWED_RENDER_MODE_TYPES, ALLOWED_MARKET_FIELD_TYPES} from '../shared';
import {BaseGuide} from './guide';

export class MarketplaceGuide extends BaseGuide {
  payload: MarketplacePayload = new MarketplacePayload();

  constructor(
    id: number = null,
    name: string = 'New marketplace guide',
    accessToken: string = null,
    url: string = null,
    currentFrequency: number = null,
    payload: MarketplacePayload = new MarketplacePayload()
  ) {
    super('marketplace', id, name, accessToken, url, currentFrequency);
    this.payload = payload;
  }
}

export class MarketplaceEntryPoint {
  sourceUrl: string;
  spiderKey: string;
  disableLogin: boolean;
  checkLogout: CheckLogout;

  constructor(
    sourceUrl: string = null,
    spiderKey: string = null,
    disableLogin: boolean = false,
    checkLogout: CheckLogout = new CheckLogout()
  ) {
    this.sourceUrl = sourceUrl;
    this.spiderKey = spiderKey;
    this.disableLogin = disableLogin;
    this.checkLogout = checkLogout;
  }
}

export class CheckLogout {
  type: string;
  method: string;
  value: string;
  isActive: boolean;
  constructor(
    type: string = null,
    method: string = null,
    value: string = null,
    isActive: boolean = false
  ) {
    if (type === 'url') {
      this.method = 'contain';
    } else {
      this.method = method;
    }
    this.type = type;
    this.value = value;
    this.isActive = !!isActive;
  }
}

export class MarketplaceField {
  name: string;
  regex: string;
  selector: MarketplaceSelector = new MarketplaceSelector();
  leadsTo: string;

  private _type: string;

  constructor(
    name: string = null,
    selector: MarketplaceSelector = new MarketplaceSelector(),
    type: string = Object.keys(ALLOWED_MARKET_FIELD_TYPES)[0],
    regex: string = null,
    leadsTo: string = null
  ) {
    this.name = name;
    this.selector = selector;
    this.type = type;
    this.regex = regex;
    this.leadsTo = leadsTo;
  }

  get type(): string {
    return this._type;
  }

  set type(type: string) {
    if (Object.keys(ALLOWED_MARKET_FIELD_TYPES).indexOf(type) > -1) {
      this._type = type;
    } else {
      throw new Error(`MarketplaceField type "${type}" is not supported`);
    }
  }
}

export class MarketplaceLeadsTo {
  spiderKey: string;

  private _type: string;

  constructor(
    spiderKey: string = null,
    type: string = Object.keys(ALLOWED_LEAD_TYPES)[0]
  ) {
    this.spiderKey = spiderKey;
    this.type = type;
  }

  get type(): string {
    return this._type;
  }

  set type(type: string) {
    if (Object.keys(ALLOWED_LEAD_TYPES).indexOf(type) > -1) {
      this._type = type;
    } else {
      throw new Error(`Lead type "${type}" is not supported`);
    }
  }
}

export class MarketplacePayload {
  entryPoint: MarketplaceEntryPoint = new MarketplaceEntryPoint();
  spiderInfo: MarketplaceSpiderInfoItem[] = [];

  constructor(
    entryPoint: MarketplaceEntryPoint = new MarketplaceEntryPoint(),
    spiderInfo: MarketplaceSpiderInfoItem[] = []
  ) {
    this.entryPoint = entryPoint;
    this.spiderInfo = spiderInfo;
  }
}

export class MarketplacePagination {
  currentCount: number;
  leadsTo: MarketplaceLeadsTo = new MarketplaceLeadsTo();
  maxCount: number;
  selector: MarketplaceSelector = new MarketplaceSelector();

  constructor(
    currentCount: number = null,
    leadsTo: MarketplaceLeadsTo = new MarketplaceLeadsTo(),
    maxCount: number = null,
    selector: MarketplaceSelector = new MarketplaceSelector()
  ) {
    this.currentCount = currentCount;
    this.leadsTo = leadsTo;
    this.maxCount = maxCount;
    this.selector = selector;
  }
}

export class MarketplaceSelector {
  element: any = null;
  path: string;

  color: string;

  private _type: string;

  constructor(
    path: string = null,
    type: string = Object.keys(ALLOWED_PATH_TYPES)[0]
  ) {
    this.path = path;
    this.type = type;
  }

  get type(): string {
    return this._type;
  }

  set type(type: string) {
    if (Object.keys(ALLOWED_PATH_TYPES).indexOf(type) > -1) {
      this._type = type;
    } else {
      throw new Error(`MarketplaceSelector type "${type}" is not supported`);
    }
  }
}

export class MarketplaceSpiderInfoItem {
  spiderKey: string;

  fields: MarketplaceField[] = [];
  leadsTo: MarketplaceLeadsTo = new MarketplaceLeadsTo();
  pagination: MarketplacePagination = new MarketplacePagination();
  selector: MarketplaceSelector = new MarketplaceSelector();

  private _pageType: string;
  private _renderMode: string|null;

  constructor(
    spiderKey: string = 'new-item',
    pageType: string = Object.keys(ALLOWED_PAGE_TYPES)[0],
    fields: MarketplaceField[] = [],
    leadsTo: MarketplaceLeadsTo = new MarketplaceLeadsTo(),
    pagination: MarketplacePagination = new MarketplacePagination(),
    selector: MarketplaceSelector = new MarketplaceSelector(),
    renderMode: string|null = Object.keys(ALLOWED_RENDER_MODE_TYPES)[0]
  ) {
    this.spiderKey = spiderKey;
    this.pageType = pageType;
    this.renderMode = renderMode;

    this.fields = fields;
    this.leadsTo = leadsTo;
    this.pagination = pagination;
    this.selector = selector;
  }

  get pageType(): string {
    return this._pageType;
  }

  set pageType(pageType: string) {
    if (Object.keys(ALLOWED_PAGE_TYPES).indexOf(pageType) > -1) {
      this._pageType = pageType;
    } else {
      throw new Error(`Page type "${pageType}" is not supported`);
    }
  }

  get renderMode(): string|null {
    return this._renderMode;
  }

  set renderMode(renderMode: string|null) {
    if (Object.keys(ALLOWED_RENDER_MODE_TYPES).indexOf(renderMode) > -1) {
      this._renderMode = renderMode;
    } else {
      throw new Error(`Page type "${renderMode}" is not supported`);
    }
  }
}
