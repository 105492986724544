import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {ToastrService} from 'ngx-toastr';

import {AuthenticationService} from '../../../services';


@Component({
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit, OnDestroy {
  signInForm: FormGroup;

  private onDestroy$ = new Subject();

  constructor(
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private router: Router,
    private toaster: ToastrService
  ) {
    if (this.authenticationService.currentUser) {
      this.router.navigate(['/configuration']);
    }
  }

  ngOnInit() {
    this.signInForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  get f() {
    return this.signInForm.controls;
  }

  onSubmit() {
    if (this.signInForm.invalid) {
      this.toaster.error('Please check the form fields');
      return;
    }

    this.authenticationService.signIn(this.f.username.value, this.f.password.value)
      .pipe(
        takeUntil(this.onDestroy$)
      )
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.toaster.success('Successfully signed in');
            this.router.navigate(['/configuration']);
          } else {
            this.toaster.error(response.response || 'Sign in failed');
          }
        },
        error => {
          console.error(error);
        });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
