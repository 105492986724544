import {Component, Input} from '@angular/core';

import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-crawler-results',
  templateUrl: './crawler-results.component.html',
  styleUrls: ['./crawler-results.component.scss']
})
export class CrawlerResultsComponent {
  @Input() jsonResponse: any;

  constructor(public activeModal: NgbActiveModal) {
  }
}
