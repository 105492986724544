import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import xPathToCss from 'xpath-to-css';

import {BrowserService, SelectionService} from '../../services';

import {UniversalSelector} from '../../models';


@Component({
  selector: 'app-browser',
  templateUrl: './browser.component.html',
  styleUrls: ['./browser.component.scss']
})
export class BrowserComponent implements OnDestroy {
  @Input() browserUrl: string;

  currentSelectors: UniversalSelector[] = [];
  isLoading = this.browserService.lastUrl && !this.browserService.lastUrlResponse;
  isWaitingForSelection = false;
  originalUrlResponse = this.browserService.lastUrlResponse;
  url = this.browserService.lastUrl;
  urlResponse = this.browserService.lastUrlResponse;

  private onDestroy$ = new Subject();

  constructor(
    private browserService: BrowserService,
    private selectionService: SelectionService
  ) {
    this.browserService.hightlightingElements$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((selectors: UniversalSelector[]) => {
      this.currentSelectors = selectors;
      this.updateHighlightStyles();
    }, error => console.error(error));
    this.browserService.urlToLoad$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((url: string) => {
      this.isLoading = true;
      this.resetBrowser();
      this.browserService.loadUrl(url);
    }, error => console.error(error));
    this.browserService.urlLoaded$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((response: any) => {
      this.isLoading = false;
      this.loadResponse(response);
    }, error => console.error(error));
    this.selectionService.waitingForSelectionState$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((state: boolean) => {
      this.isWaitingForSelection = state;
      this.updateHighlightStyles();
    }, error => console.error(error));
    setInterval(() => {
      this.updateHighlightStyles();
    }, 400);
  }

  loadResponse(response: any) {
    this.url = response.url;
    this.originalUrlResponse = response.response;
    this.urlResponse = response.response;
    this.updateHighlightStyles();
  }

  onBrowserUrlChange() {
    this.browserService.loadUrl(this.browserUrl);
  }

  resetBrowser() {
    this.url = null;
    this.urlResponse = null;
  }

  updateHighlightStyles() {
    if (this.originalUrlResponse) {
      this.urlResponse = null;
      if (this.isWaitingForSelection) {
        this.urlResponse = this.originalUrlResponse;
        return;
      }
      const cssSelectors = this.currentSelectors.filter(selector => selector.type === 'css'),
        XPathSelectors = this.currentSelectors.filter(selector => selector.type === 'xpath');
      let resultStyles = '';

      XPathSelectors.map(selector => {
        try {
          cssSelectors.push(new UniversalSelector(
            selector.color,
            selector.name,
            xPathToCss(selector.selector),
            'css'
          ));
        } catch (e) {
          console.error('XPath invalid');
        }
      });

      cssSelectors.map(selector => {
        resultStyles +=
          `${selector.selector} { position: relative !important; ` +
          `border: 2px solid ${selector.color} !important; } `;
        resultStyles +=
          `${selector.selector}:before { content: ''; position: absolute !important; ` +
          `top: 0 !important; left: 0 !important; width: 100% !important; height: 100% !important; ` +
          `background-color: ${selector.color} !important; opacity: .5 !important; z-index: 5 !important; } `;
        resultStyles +=
          `${selector.selector}:after { content: '${selector.name}'; position: absolute !important; ` +
          `top: 50% !important; left: 50% !important; display: inline-block !important; ` +
          `max-width: 100% !important; color: #fff !important; font-size: 12px !important; ` +
          `text-shadow: 1px 1px 3px rgba(0, 0, 0, .75) !important; transform: translate(-50%, -50%) !important; ` +
          `z-index: 10 !important; overflow: hidden !important; } `;
      });

      const urlResponseParts = this.originalUrlResponse.split('</body>');
      this.urlResponse = urlResponseParts[0] + `<style>${resultStyles}</style>` + urlResponseParts[1];
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
