import {Component, EventEmitter, Input, OnChanges, OnDestroy, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {faCrosshairs, faMousePointer} from '@fortawesome/free-solid-svg-icons';

import {ALLOWED_PATH_TYPES, FORUM_PATH_NAMES, HIGHLIGHT_COLORS} from '../../../shared';

import {BrowserService, PathService} from '../../../services';

import {ForumPayload, ForumPath, UniversalSelector} from '../../../models';


@Component({
  selector: 'app-forum-configuration',
  templateUrl: './forum-configuration.component.html'
})
export class ForumConfigurationComponent implements OnChanges, OnDestroy {
  @Input() browserState: boolean;
  @Input() isWaitingForSelection: boolean;
  @Input() payload: ForumPayload;
  @Output() browserRequested = new EventEmitter<ForumPath>();

  activeType = 'fo';
  activePaths = [];

  readonly ALLOWED_PATH_TYPES = ALLOWED_PATH_TYPES;
  readonly FORUM_PATH_NAMES = FORUM_PATH_NAMES;
  readonly FORUM_BLOCK_PATH_ALIASES = ['fo_block', 'fp_thread_block', 'th_post_block'];

  readonly faCrosshairs = faCrosshairs;
  readonly faMousePointer = faMousePointer;

  private onDestroy$ = new Subject();

  constructor(
    private browserService: BrowserService,
    private pathService: PathService
  ) {
    this.browserService.updateHightlightingElements$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
      this.highlightSelectedTypePaths();
    });
  }

  ngOnChanges(): void {
    this.updateActivePaths();
  }

  highlightSelectedTypePaths() {
    const selectors: UniversalSelector[] = [];

    this.payload.paths = this.payload.paths.map((path: ForumPath) => {
      if (path.name.startsWith(this.activeType)) {
        if (!path.color) {
          path.color = HIGHLIGHT_COLORS[Math.floor(Math.random() * HIGHLIGHT_COLORS.length)];
        }

        if (path.path) {
          selectors.push(
            new UniversalSelector(
              path.color,
              path.name,
              path.path,
              path.pathType
            )
          );
        }
      }
      return path;
    });

    console.log('selectors', selectors);

    this.browserService.hightlightElements(selectors);
  }

  selectPath(path: ForumPath) {
    this.browserRequested.emit(path);
  }

  switchType(type: string) {
    this.activeType = type;
    this.highlightSelectedTypePaths();
    this.updateActivePaths();
  }

  updatePath(path: ForumPath) {
    this.pathService.updateItemPath(path, 'forum');
    this.highlightSelectedTypePaths();
  }

  private updateActivePaths() {
    this.activePaths = this.payload.paths.filter((path: ForumPath) => path.name.startsWith(this.activeType));
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
