import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {faChevronLeft, faChevronRight, faSignOutAlt, faSpinner} from '@fortawesome/free-solid-svg-icons';
import {faEdit, faTrashAlt} from '@fortawesome/free-regular-svg-icons';

import {ToastrService} from 'ngx-toastr';

import {PER_PAGE_VALUES} from '../../shared';

import {AuthenticationService, GuideService} from '../../services';

import {BaseGuide, GuideFilter} from '../../models';


@Component({
  templateUrl: './guide-list.component.html',
  styleUrls: ['./guide-list.component.scss']
})
export class GuideListComponent implements OnInit {
  filter: GuideFilter = new GuideFilter();
  guides: BaseGuide[] = null;
  page = 1;
  perPage = localStorage.getItem('perPage') ? parseInt(localStorage.getItem('perPage'), 10) : 10;

  readonly PER_PAGE_VALUES = PER_PAGE_VALUES;
  readonly faChevronLeft = faChevronLeft;
  readonly faChevronRight = faChevronRight;
  readonly faEdit = faEdit;
  readonly faSignOutAlt = faSignOutAlt;
  readonly faSpinner = faSpinner;
  readonly faTrashAlt = faTrashAlt;

  constructor(
    private authenticationService: AuthenticationService,
    private guideService: GuideService,
    private router: Router,
    private toaster: ToastrService
  ) {
  }

  ngOnInit() {
    this.loadGuides();
  }

  createNewGuide(event: any) {
    event.preventDefault();
    this.router.navigate(['/configuration', 0]);
  }

  deleteGuide(guide: BaseGuide) {
    if (!confirm(`Are you sure you want to delete #${guide.id} "${guide.name}" guide?`)) {
      return;
    }
    this.guideService.deleteGuide(guide.id).subscribe((response: any) => {
      if (response.success) {
        this.toaster.success(response.response);
        this.loadGuides();
      } else {
        this.toaster.error(response.response);
      }
    }, error => console.error(error));
  }

  loadGuides() {
    this.guideService.getGuides(this.page, this.perPage, this.filter).subscribe(
      (response: any) => {
        if (response.success) {
          this.guides = response.response;
        } else {
          this.guides = [];
          this.page = 1;
          this.toaster.error(response.response);
          console.error(response.response);
        }
      }, error => console.error(error));
  }

  signOut() {
    this.authenticationService.signOut().subscribe(
      (response: any) => {
        if (response.success) {
          this.router.navigate(['/sign-in']);
          this.toaster.success(response.response);
        } else {
          this.toaster.error(response.response);
        }
      },
      error => {
        console.error(error);
      });
  }

  nextPage() {
    this.page++;
    this.loadGuides();
  }

  onFiltersChange() {
    this.page = 1;
    this.loadGuides();
  }

  prevPage() {
    this.page--;
    this.loadGuides();
  }

  selectGuide(guide: BaseGuide) {
    this.router.navigate(['/configuration', guide.id]);
  }

  updatePerPage(perPage: number) {
    this.page = 1;
    localStorage.setItem('perPage', perPage.toString());
    this.perPage = perPage;
    this.loadGuides();
  }
}
