import {ALLOWED_PATH_TYPES, FORUM_PATH_NAMES} from '../shared';

import {BaseGuide} from './guide';

export class ForumGuide extends BaseGuide {
  payload: ForumPayload = new ForumPayload();

  constructor(
    id: number = null,
    name: string = 'New forum guide',
    accessToken: string = null,
    url: string = null,
    currentFrequency: number = null,
    payload: ForumPayload = new ForumPayload()
  ) {
    super('forum', id, name, accessToken, url, currentFrequency);
    this.payload = payload;
  }
}

export class ForumPayload {
  paths: ForumPath[] = [];

  constructor(paths: ForumPath[] = []) {
    this.paths = paths;

    const missedNames = Object.keys(FORUM_PATH_NAMES);

    this.paths.map((path: ForumPath) => {
      const index = missedNames.indexOf(path.name);
      if (index > -1) {
        missedNames.splice(index, 1);
      }
    });

    missedNames.map((key: string) => {
      this.paths.push(new ForumPath(null, key));
    });
  }
}

export class ForumPath {
  id: number;
  name: string;
  sourceId: number;
  element: any = null;
  path: string;
  regex: string;

  color: string;

  private _pathType: string;

  constructor(
    id: number = null,
    name: string = null,
    sourceId: number = null,
    path: string = null,
    regex: string = null,
    pathType: string = Object.keys(ALLOWED_PATH_TYPES)[0]
  ) {
    this.id = id;
    this.name = name;
    this.sourceId = sourceId;
    this.path = path;
    this.regex = regex;
    this.pathType = pathType;
  }

  get pathType(): string {
    return this._pathType;
  }

  set pathType(pathType: string) {
    if (Object.keys(ALLOWED_PATH_TYPES).indexOf(pathType) > -1) {
      this._pathType = pathType;
    } else {
      throw new Error(`ForumPath type "${pathType}" is not supported`);
    }
  }
}
