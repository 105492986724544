import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ReplaySubject, Subject} from 'rxjs';

import {environment} from '../../environments/environment';

import {UniversalSelector} from '../models';


@Injectable({
  providedIn: 'root'
})
export class BrowserService {
  lastBrowserState = false;
  lastUrl = null;
  lastUrlResponse = null;

  private browserState = new Subject<boolean>();
  readonly browserState$ = this.browserState.asObservable();

  private hightlightingElements = new ReplaySubject<UniversalSelector[]>(2);
  readonly hightlightingElements$ = this.hightlightingElements.asObservable();

  private updateHightlightingElements = new ReplaySubject<null>(2);
  readonly updateHightlightingElements$ = this.updateHightlightingElements.asObservable();

  private urlToLoad = new Subject<string>();
  readonly urlToLoad$ = this.urlToLoad.asObservable();

  private urlLoaded = new Subject<any>();
  readonly urlLoaded$ = this.urlLoaded.asObservable();

  constructor(private http: HttpClient) {
  }

  hightlightElements(selectors: UniversalSelector[] = []) {
    this.hightlightingElements.next(selectors);
  }

  loadUrl(url: string) {
    this.http.get(
      `${environment.api.baseUrl}${environment.api.routes.page}`,
      {
        params: {
          url: url
        }
      }).subscribe(
      (response: any) => {
        this.urlResponse(response);
      }, error => console.error(error));
  }

  setBrowserState(state: boolean) {
    this.lastBrowserState = state;
    this.browserState.next(state);
  }

  updateHighlightingElements() {
    this.updateHightlightingElements.next();
  }

  urlResponse(response: any) {
    this.lastUrl = response.url;
    this.lastUrlResponse = response.response;
    this.urlLoaded.next(response);
  }
}
