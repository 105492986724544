import {NEWS_PATH_NAMES} from '../shared';

import {BaseGuide} from './guide';

export class NewsGuide extends BaseGuide {
  payload: NewsPayload = new NewsPayload();

  constructor(
    id: number = null,
    name: string = 'New news guide',
    accessToken: string = null,
    url: string = null,
    currentFrequency: number = null,
    payload: NewsPayload = new NewsPayload()
  ) {
    super('news', id, name, accessToken, url, currentFrequency);
    this.payload = payload;
  }
}

export class NewsPayload {
  filters: NewsFilter[] = [];

  constructor(filters: NewsFilter[] = []) {
    this.filters = filters;
  }
}

export class NewsFilter {
  id: number;
  name: string;
  parentId: number;
  paths: NewsPath[] = [];

  constructor(
    id: number = null,
    name: string = 'New NewsFilter',
    paths: NewsPath[] = [],
    parentId: number = null) {
    this.id = id;
    this.name = name;
    this.paths = paths;
    this.parentId = parentId;

    const missedNames = Object.keys(NEWS_PATH_NAMES);

    this.paths.map((path: NewsPath) => {
      const index = missedNames.indexOf(path.type);

      if (index > -1) {
        missedNames.splice(index, 1);
      }
    });

    missedNames.map((key: string) => {
      this.paths.push(new NewsPath(null, null, key));
    });
  }
}

export class NewsPath {
  id: number;
  element: any = null;
  path: string;
  type: string;
  filterId: number|null;

  color: string;

  constructor(
    id: number = null,
    path: string = null,
    type: string = null,
    filterId: number|null = null
  ) {
    this.id = id;
    this.path = path;
    this.type = type;
    this.filterId = filterId
  }
}
