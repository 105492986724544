import {Component, Input} from '@angular/core';

import {faTrashAlt} from '@fortawesome/free-regular-svg-icons';
import {faPlus} from '@fortawesome/free-solid-svg-icons';

import {ALLOWED_HEADER_KEYS} from '../../../shared';

import {CreditCardHeader, CreditCardParamDynamic, CreditCardParamStatic, CreditCardPayload} from '../../../models';


@Component({
  selector: 'app-credit-card-configuration',
  templateUrl: './credit-card-configuration.component.html'
})
export class CreditCardConfigurationComponent {
  @Input() payload: CreditCardPayload;

  activeSection = 'entryPoint';

  readonly faPlus = faPlus;
  readonly faTrashAlt = faTrashAlt;

  addLevel() {
    this.payload.apiConfig.responseKeyPath.push('');
  }

  addHeader() {
    if (this.avaliableHeaderKeys().length) {
      this.payload.headers.push(new CreditCardHeader(this.avaliableHeaderKeys()[0]));
    } else {
      throw new Error('No more header keys available');
    }
  }

  addDynamicQueryParam() {
    this.payload.apiConfig.params.dynamicList.push(new CreditCardParamDynamic());
  }

  addStaticQueryParam() {
    this.payload.apiConfig.params.staticList.push(new CreditCardParamStatic());
  }

  avaliableHeaderKeys(additionalKey: string = null) {
    const avaliableHeaderKeys = ALLOWED_HEADER_KEYS.filter((key: string) => {
      let found = false;
      this.payload.headers.map((header: CreditCardHeader) => {
        if (header.key === key) {
          found = true;
        }
      });
      return !found;
    });
    if (additionalKey) {
      avaliableHeaderKeys.push(additionalKey);
    }
    return avaliableHeaderKeys;
  }

  deleteKeyPathItem(index: number) {
    this.payload.apiConfig.responseKeyPath.splice(index, 1);
  }

  deleteHeader(index: number) {
    this.payload.headers.splice(index, 1);
  }

  deleteDynamicQueryParameter(index: number) {
    this.payload.apiConfig.params.dynamicList.splice(index, 1);
  }

  deleteStaticQueryParameter(index: number) {
    this.payload.apiConfig.params.staticList.splice(index, 1);
  }

  switchSection(section: string) {
    this.activeSection = section;
  }
}
