import {Injectable} from '@angular/core';

import {ForumPath, MarketplaceSelector, NewsPath} from '../models';


@Injectable({
  providedIn: 'root'
})
export class PathService {

  getElementTree(element: any, full = false): any[] {
    const parents = [element];
    let parentNode = element.parentNode;
    while (parentNode && (full || parentNode.tagName !== 'OBJECT' && parentNode.id !== 'browserObject')) {
      parents.push(parentNode);
      parentNode = parentNode.parentNode;
    }
    return parents.reverse();
  }

  updateItemPath(item: any, sourceType: string) {
    switch (sourceType) {
      case 'forum':
        this.updateForumPath(item);
        break;
      case 'marketplace':
        this.updateMarketplaceSelector(item);
        break;
      case 'news':
        this.updateNewsPath(item);
        break;
      default:
        throw new Error(`Source type "${sourceType}" is not supported`);
    }
  }

  private updateForumPath(path: ForumPath) {
    const element = path.element;
    if (!element) {
      return;
    }
    switch (path.pathType) {
      case 'css':
        path.path = this.generateCSSPath(element);
        break;
      case 'xpath':
        path.path = this.generateXPathPath(element);
        break;
      default:
        throw new Error(`Forum path type "${path.pathType}" is not supported`);
    }
  }

  private updateMarketplaceSelector(selector: MarketplaceSelector) {
    const element = selector.element;
    if (!element) {
      throw new Error('Selector element is not defined');
    }
    switch (selector.type) {
      case 'css':
        selector.path = this.generateCSSPath(element);
        break;
      case 'xpath':
        selector.path = this.generateXPathPath(element);
        break;
      default:
        throw new Error(`Marketplace path type "${selector.type}" is not supported`);
    }
  }

  private updateNewsPath(path: NewsPath) {
    const element = path.element;
    if (!element) {
      throw new Error('Path element is not defined');
    }
    path.path = this.generateXPathPath(element);
  }

  private generateCSSPath(item: any) {
    let path = '';
    this.getElementTree(item).map(element => {
      if (path.length) {
        path += ' > ';
      }
      path += element.tagName.toLowerCase();
      if (element.id) {
        path += `#${element.id}`;
      }
      if (element.className && element.className.length > 0) {
        const classNames = element
          .className
          .split(' ')
          .filter(s => s !== 'selected-by-crawler' && s.length);
        if (classNames.length) {
          path += `.${classNames.join('.')}`;
        }
      }
    });
    return path;
  }

  private generateXPathPath(item: any) {
    let path = '/';
    this.getElementTree(item).map(element => {
      path += `/${element.tagName.toLowerCase()}`;
      if (element.id) {
        path += `[@id="${element.id}"]`;
      }
      if (element.className && element.className.length > 0) {
        const classNames = element
          .className
          .split(' ')
          .filter(s => s !== 'selected-by-crawler' && s.length);

        path += `[contains(concat(' ', normalize-space(@class), ' '), '${classNames.join(' ')}')]`;
      }
    });
    return path;
  }
}
