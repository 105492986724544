import {Component, Input, OnChanges, OnDestroy, SimpleChanges} from "@angular/core";
import {SocialMediaGuide} from "../../../models";

@Component({
  selector: 'app-social-media-configuration',
  templateUrl: './social-media-configuration.component.html',
  styleUrls: ['./social-media-configuration.component.scss']
})
export class SocialMediaConfigurationComponent implements OnChanges, OnDestroy {
  @Input() guide: SocialMediaGuide;

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnDestroy() {
  }

}
