import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {faTrashAlt} from '@fortawesome/free-regular-svg-icons';
import {faCrosshairs, faMousePointer, faPlus} from '@fortawesome/free-solid-svg-icons';

import {
  ALLOWED_MARKET_FIELD_TYPES,
  ALLOWED_LEAD_TYPES,
  ALLOWED_PAGE_TYPES,
  ALLOWED_PATH_TYPES,
  HIGHLIGHT_COLORS,
  ALLOWED_RENDER_MODE_TYPES,
  ALLOWED_FIELD_TYPES
} from '../../../shared';

import {BrowserService, PathService} from '../../../services';

import {
  CheckLogout,
  MarketplaceField,
  MarketplacePagination,
  MarketplacePayload,
  MarketplaceSelector,
  MarketplaceSpiderInfoItem,
  UniversalSelector
} from '../../../models';


@Component({
  selector: 'app-marketplace-configuration',
  templateUrl: './marketplace-configuration.component.html',
  styleUrls: ['./marketplace-configuration.component.scss']
})
export class MarketplaceConfigurationComponent implements OnDestroy {
  @Input() browserState: boolean;
  @Input() isWaitingForSelection: boolean;
  @Input() payload: MarketplacePayload;
  @Output() browserRequested = new EventEmitter<MarketplaceSelector>();

  activeSection = 'entryPoint';

  readonly ALLOWED_FIELD_TYPES = ALLOWED_MARKET_FIELD_TYPES;
  readonly ALLOWED_LEAD_TYPES = ALLOWED_LEAD_TYPES;
  readonly ALLOWED_PAGE_TYPES = ALLOWED_PAGE_TYPES;
  readonly ALLOWED_RENDER_MODE_TYPES = ALLOWED_RENDER_MODE_TYPES;
  readonly ALLOWED_PATH_TYPES = ALLOWED_PATH_TYPES;

  readonly faCrosshairs = faCrosshairs;
  readonly faMousePointer = faMousePointer;
  readonly faPlus = faPlus;
  readonly faTrashAlt = faTrashAlt;

  private onDestroy$ = new Subject();

  constructor(
    private browserService: BrowserService,
    private pathService: PathService
  ) {
    this.browserService.updateHightlightingElements$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
      this.highlightSelectedStepPaths();
    });
  }

  addField(spiderInfoItem: MarketplaceSpiderInfoItem) {
    spiderInfoItem.fields.push(new MarketplaceField());
  }

  addSpiderInfoItem() {
     if (!this.payload.spiderInfo) {
        this.payload.spiderInfo = [];
    }

    this.payload.spiderInfo.push(new MarketplaceSpiderInfoItem());
  }

  deleteField(spiderInfoItem: MarketplaceSpiderInfoItem, index: number) {
    spiderInfoItem.fields.splice(index, 1);
  }

  deleteSpiderInfoItem(index: number) {
    this.payload.spiderInfo.splice(index, 1);
  }

  disablePagination(spiderInfoItem: MarketplaceSpiderInfoItem) {
    if (spiderInfoItem.pagination) {
      spiderInfoItem.pagination = null;
      this.highlightSelectedStepPaths();
    }
  }

  enablePagination(spiderInfoItem: MarketplaceSpiderInfoItem) {
    if (!spiderInfoItem.pagination) {
      spiderInfoItem.pagination = new MarketplacePagination();
      this.highlightSelectedStepPaths();
    }
  }

  highlightSelectedStepPaths() {
    const selectors: UniversalSelector[] = [];

    if (this.activeSection !== 'entryPoint') {
      this.payload.spiderInfo =
        this.payload.spiderInfo.map((spiderInfoItem: MarketplaceSpiderInfoItem, index: number) => {
          if (this.activeSection === 'spiderInfoItem' + index) {
            if (spiderInfoItem.fields) {
              spiderInfoItem.fields = spiderInfoItem.fields.map((field: MarketplaceField) => {
                if (!field.selector.color) {
                  field.selector.color = HIGHLIGHT_COLORS[Math.floor(Math.random() * HIGHLIGHT_COLORS.length)];
                }

                if (field.selector.path) {
                  selectors.push(
                    new UniversalSelector(
                      field.selector.color,
                      field.name,
                      field.selector.path,
                      field.selector.type
                    )
                  );
                }
                return field;
              });
            }

            if (spiderInfoItem.pagination && !spiderInfoItem.pagination.selector.color) {
              spiderInfoItem.pagination.selector.color = HIGHLIGHT_COLORS[Math.floor(Math.random() * HIGHLIGHT_COLORS.length)];
            }

            if (spiderInfoItem.pagination && spiderInfoItem.pagination.selector.path) {
              selectors.push(
                new UniversalSelector(
                  spiderInfoItem.pagination.selector.color,
                  'Pagination selector',
                  spiderInfoItem.pagination.selector.path,
                  spiderInfoItem.pagination.selector.type
                )
              );
            }

            if (spiderInfoItem.selector && !spiderInfoItem.selector.color) {
              spiderInfoItem.selector.color = HIGHLIGHT_COLORS[Math.floor(Math.random() * HIGHLIGHT_COLORS.length)];
            }

            if (spiderInfoItem.selector && spiderInfoItem.selector.path) {
              selectors.push(
                new UniversalSelector(
                  spiderInfoItem.selector.color,
                  'Step selector',
                  spiderInfoItem.selector.path,
                  spiderInfoItem.selector.type
                )
              );
            }
          }
          return spiderInfoItem;
        });
    }

    console.log('selectors', selectors);

    this.browserService.hightlightElements(selectors);
  }

  selectPath(selector: MarketplaceSelector) {
    this.browserRequested.emit(selector);
  }

  spiderInfoItemSiblings(spiderInfoItem: MarketplaceSpiderInfoItem) {
    return this.payload.spiderInfo.filter(item => item.spiderKey !== spiderInfoItem.spiderKey);
  }

  cleanField(f) {
    f = null;
  }

  switchSection(section: string) {
    const newBrowserState = section !== 'entryPoint';
    if (newBrowserState !== this.browserState) {
      this.browserService.setBrowserState(newBrowserState);
    }
    this.activeSection = section;
    this.highlightSelectedStepPaths();
  }

  updateSelectorPath(selector: MarketplaceSelector) {
    this.pathService.updateItemPath(selector, 'marketplace');
    this.highlightSelectedStepPaths();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  disableCheckLogout()
  {
      this.payload.entryPoint.checkLogout = new CheckLogout();
  }

  enableCheckLogout()
  {
      this.payload.entryPoint.checkLogout = new CheckLogout();
      this.payload.entryPoint.checkLogout.isActive = true;
  }

  setCheckLoginMethod()
  {
    let type = this.payload.entryPoint.checkLogout.type;

    if (type === 'url') {
      this.payload.entryPoint.checkLogout.method = 'contain';
    }

  }

  cleanCheckLogoutValue() {
    this.payload.entryPoint.checkLogout.value = null;
  }
}
