import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {environment} from '../../environments/environment';

import {GuideFilter} from '../models';


@Injectable({
  providedIn: 'root'
})
export class GuideService {
  constructor(
    private http: HttpClient
  ) {
  }

  deleteGuide(id: number) {
    return this.http.delete(`${environment.api.baseUrl}${environment.api.routes.guide}/${id}`);
  }

  getGuides(page: number = 1, perPage: number = 10, filter: GuideFilter = null) {
    const params = {
      'page': page.toString(),
      'per_page': perPage.toString()
    };
    if (filter.id) {
      params['filter[id]'] = filter.id.toString();
    }
    if (filter.name) {
      params['filter[name]'] = filter.name.toString();
    }
    if (filter.sourceType) {
      params['filter[source_type]'] = filter.sourceType.toString();
    }
    if (filter.url) {
      params['filter[url]'] = filter.url.toString();
    }
    return this.http.get(
      `${environment.api.baseUrl}${environment.api.routes.guide}`,
      {
        params: params
      });
  }

  getGuide(id: number) {
    return this.http.get(`${environment.api.baseUrl}${environment.api.routes.guide}/${id}`);
  }

  saveGuide(guide: any) {
    if (guide.id) {
      return this.http.put(`${environment.api.baseUrl}${environment.api.routes.guide}/${guide.id}`, guide);
    } else {
      return this.http.post(`${environment.api.baseUrl}${environment.api.routes.guide}`, guide);
    }
  }
}
