import {ALLOWED_PATH_TYPES} from '../shared';

export class UniversalSelector {
  color: string;
  name: string;
  selector: string;
  private _type: string;

  constructor(color: string, name: string, selector: string, type: string) {
    this.color = color;
    this.name = name;
    this.selector = selector;
    this.type = type;
  }

  get type(): string {
    return this._type;
  }

  set type(type: string) {
    if (Object.keys(ALLOWED_PATH_TYPES).indexOf(type) > -1) {
      this._type = type;
    } else {
      throw new Error(`Path type "${type}" is not supported`);
    }
  }
}
