import {Component, Input, OnDestroy, ViewEncapsulation} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {isFunction} from 'util';

import {BrowserService, PathService, SelectionService} from '../../../services';


@Component({
  selector: 'app-website-viewer',
  templateUrl: './website-viewer.component.html',
  styleUrls: ['./website-viewer.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class WebsiteViewerComponent implements OnDestroy {
  @Input() urlResponse: any;
  isWaitingForSelection = false;

  private onDestroy$ = new Subject();

  constructor(
    private browserService: BrowserService,
    private pathService: PathService,
    private selectionService: SelectionService
  ) {
    this.selectionService.waitingForSelectionState$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((state: boolean) => {
      this.isWaitingForSelection = state;
    }, error => console.error(error));
  }

  click(event) {
    event.preventDefault();
    if (this.isHtmlResponse()) {
      this.pathService.getElementTree(event.target, true).map(element => {
        if (element.tagName === 'OBJECT' && element.id === 'browserObject') {
          this.selectionService.selectElement(event.target);
          return;
        }
      });
      if (event.target.className && isFunction(event.target.className.replace)) {
        event.target.className = event.target.className.replace(' selected-by-crawler', '');
      }
    }
  }

  mouseOver(event) {
    if (this.isHtmlResponse() && this.isWaitingForSelection) {
      event.target.className += ' selected-by-crawler';
    }
  }

  mouseOut(event) {
    if (this.isHtmlResponse() && event.target.className && isFunction(event.target.className.replace)) {
      event.target.className = event.target.className.replace(' selected-by-crawler', '');
    }
  }

  isHtmlResponse() {
    return this.urlResponse.indexOf('<html') > -1 &&
      this.urlResponse.indexOf('<head') > -1 &&
      this.urlResponse.indexOf('<body') > -1;
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
