import {Component, Input, OnChanges, OnDestroy, SimpleChanges} from "@angular/core";
import {InstagramPayload} from "../../../../models";


@Component({
  selector: 'app-instagram-configuration',
  templateUrl: './instagram-configuration.component.html',
  styleUrls: ['./instagram-configuration.component.scss']
})
export class InstagramConfigurationComponent implements OnChanges, OnDestroy {
  @Input() payload: InstagramPayload;

  activeSection = 'entryPoint';

  readonly ALLOWED_TYPES = {
    'username': 'Username',
    'tagName': 'Tag Name',
    'userNameSearch': 'User Name Search'
  };


  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnDestroy() {
  }

}
