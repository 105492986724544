import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import {AngularMultiSelectModule} from 'angular2-multiselect-dropdown';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {
  NgbDropdownModule,
  NgbModalModule,
  NgbProgressbarModule,
  NgbTabsetModule
} from '@ng-bootstrap/ng-bootstrap';
import {NgxJsonViewerModule} from 'ngx-json-viewer';
import {ToastrModule} from 'ngx-toastr';

import {ApiInterceptor} from './helpers';
import {RoutingModule} from './routing.module';

import {AppComponent} from './app.component';
import {
  BrowserComponent,
  ConfigurationComponent,
  CrawlerResultsComponent,
  CreditCardConfigurationComponent,
  ForumConfigurationComponent,
  GuideListComponent,
  MarketplaceConfigurationComponent,
  NewsConfigurationComponent,
  SignInComponent,
  WebsiteViewerComponent,
  InstagramConfigurationComponent,
  SocialMediaConfigurationComponent
} from './components';

import {SanitizeHtmlPipe} from './pipes';


@NgModule({
  declarations: [
    AppComponent,
    BrowserComponent,
    ConfigurationComponent,
    CrawlerResultsComponent,
    CreditCardConfigurationComponent,
    ForumConfigurationComponent,
    GuideListComponent,
    MarketplaceConfigurationComponent,
    NewsConfigurationComponent,
    SignInComponent,
    WebsiteViewerComponent,
    SanitizeHtmlPipe,
    InstagramConfigurationComponent,
    SocialMediaConfigurationComponent
  ],
  entryComponents: [
    CrawlerResultsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AngularMultiSelectModule,
    FontAwesomeModule,
    NgbDropdownModule,
    NgbModalModule,
    NgbProgressbarModule,
    NgbTabsetModule,
    NgxJsonViewerModule,
    ToastrModule.forRoot()
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
