import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {environment} from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SiloService {
  constructor(
    private http: HttpClient
  ) {
  }

  getSilos() {
    return this.http.get(`${environment.api.baseUrl}${environment.api.routes.silo}`);
  }
}
